import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import ContentRenderer from "components/ContentRenderer/ContentRenderer";

const PartnerLandingPage = ({ data, pageContext, location }) => {
  const blocks = data.datoCmsPartnerlandingpage.content;
  const seo = data.datoCmsPartnerlandingpage.seoMetaTags;

  return (
    <>
      <HelmetDatoCms seo={seo}>
      </HelmetDatoCms>
      <ContentRenderer blocks={blocks} />
    </>
  );
};

export default PartnerLandingPage;

export const pageQuery = graphql`
  query ($title: String!) {
    datoCmsPartnerlandingpage(title: { eq: $title }) {
      id: originalId
      title
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      navbarColors
      content {
        ...AccordionImage
        ...BookADemo
        ...FAQ
        ...FeatureOverview
        ...FormReference
        ...FullscreenHero
        ...FullscreenPopHero
        ...ImageGrid
        ...Ipad
        ...List
        ...MultipleModule
        ...OpenPositions
        ...PartnerPricingReference
        ...PricingReference
        ...PublicationGrid
        ...RichText
        ...RichTextTwoCol
        ...StandardHero
        ...TableReference
        ...TestimonialCarousel
        ...TextImage
        ...TitleBox
        ...TitleColumns
        ...TitleQuote
        ...TitleSubtitle
        ...TitleText
        ...TrustedBy
        ...USPReference
        ...VideoPlayer
        ...HeroTextImage
        ...QuickInfo
      }
    }
  }
`;
